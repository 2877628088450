let Data = [
    {
        id:0,
        title: "Biovet Peshtera",
        description: "the project for the milkeyway in Smolian has started in 2007, and it took around 2 years for it to finish etc..",
        url: "./Images/constr.png"
    },
    {
        id:1,
        title: "Samokov Sports hall",
        description: "the project for the milkeyway in Sofia has started in 2013",
        url: "./Images/cables01.png"
    },
    {
        id:2,
        title: "Stamboliiski Paper Factory",
        description: "Peshtera is the biggest project that we've worked upon ",
        url: "./Images/obekt_01.jpeg"
    },
    {
        id:3,
        title: "Telenor Shops",
        description: "the project for the milkeyway in Smolian has started in 2007, and it took around 2 years for it to finish etc..",
        url: "./Images/constructions.jpg"
    },
    {
        id:4,
        title: "Graffity Cafee",
        description: "the project for the milkeyway in Smolian has started in 2007, and it took around 2 years for it to finish etc..",
        url: "./Images/construction.jpg"
    },
    {
        id:5,
        title: "Last Project",
        description: "the project for the milkeyway in Smolian has started in 2007, and it took around 2 years for it to finish etc..",
        url: "./Images/copper.jpg"
    },
    {
        id:6,
        title: "Haskovo's Resort",
        description: "This is one of the lastest projects",
        url: "./Images/Vigilance.jpg"
    },
    {
        id:7,
        title: "Sofia Mall",
        description: "Sofia mall is a project that has its own touch of unqiueness",
        url: "./Images/const5.jpg"
    },
    {
        id:8,
        title: "Project Romania",
        description: "Since the company have had the opportunity to grow and expand we've now started a brand new project in Romania",
        url: "./Images/Construction-6.jpg"
    }
]

export default Data